import { DefaultConfig, defaultConfig } from './default';
import { ProductionConfig, productionConfig } from './production';

export type ConfigKey = keyof ProductionConfig | keyof DefaultConfig;

export function getConfig<T extends ConfigKey>(key: T) {
  if (isProduction() && hasProductionConfig(key)) {
    return productionConfig[key] ?? defaultConfig[key];
  } else {
    return defaultConfig[key];
  }
}

function isProduction() {
  return process.env.NODE_ENV === 'production';
}

function hasProductionConfig(key: ConfigKey): key is keyof ProductionConfig {
  return key in productionConfig;
}
