import MomentUtils from '@date-io/moment';
import AutocompleteProvider from '@elentari/components/AutocompleteProvider';
import { withStyles } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Auth as AuthProvider, loginRoutes } from 'elentari';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Route from './lib/Route';
import { SWRConfig } from 'swr';
import MessageSnackbar from './Components/MessageSnackbar';
import { tokenRefreshedEventEmitter } from './events/token-refreshed-event-emitter';
import './font.css';
import Home from './HomeNavigation';
import useFreshDesk from './hooks/useFreshDesk';
import useSalesMachine from './hooks/useSalesMachine';
import RedirectNotFound from './lib/RedirectNotFound';
import { SnackBarContext } from './lib/SnackBarContext';
import { featuraValidationRepository } from './modules/features/featureValidationRepository';
import {
  Login,
  RecoverPassword,
  ChangePassword,
  VerifyLogin,
} from './modules/login';
import VerifyAuthorization from './modules/login/VerifyAuthorization';
import NovoClienteSnackbar from './modules/notificacoes/snackbars/novo-cliente/NovoClienteSnackbar';
import { AbilityContext, abilityFor, rolesRepository } from './modules/users';
import { FeaturesContext } from './modules/users/FeaturesContext';
import { Actions, RealTimeProvider } from './realtime/RealTimeProvider';
import api from './services/api';
import theme from './Theme';
import GoogleAgenda from './modules/notificacoes/snackbars/google-agenda/GoogleAgendaSnackbar';
import PrivateRoute from './lib/PrivateRoute';

const styles = () => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '.5em!important',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary[200],
      borderRadius: 16,
      '&:hover': {
        backgroundColor: theme.palette.primary[300],
      },
    },
    '*::-webkit-scrollbar:horizontal': {
      height: '.5em',
    },
    '#fc_frame:not(.MuiDrawer-root):not(.MuiPopover-root)': {
      left: '-5px!important',
      bottom: '-5px!important',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  containerTopOffset: {
    top: 60,
  },
  zindex: {
    zIndex: '1300',
  },
}));

const App = () => {
  moment.locale('pt-br');
  useFreshDesk({
    url: 'https://wchat.freshchat.com/js/widget.js',
  });
  useSalesMachine();

  const [user, setUser] = useState();
  const [ability, setAbility] = useState();
  const [features, setFeatures] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    state: {},
  });
  const classes = useStyles();
  const withUser = async (user) => {
    setUser(user);
    api.setToken(user.token);

    const featuresResponse = await featuraValidationRepository.list();
    if (featuresResponse.ok) {
      setFeatures(featuresResponse.data);
    }

    if (user.roles === 'SUPER_ADMIN') {
      setAbility(abilityFor(user));
      return;
    }

    const rolesResponse = await rolesRepository.getOne({ id: user.roleId });
    if (rolesResponse.ok) {
      setAbility(abilityFor(user, rolesResponse.data));
    }
  };

  const onLogout = (dispatch) => () => {
    api.logout();
    dispatch({ type: Actions.DISCONNECT });
    setAbility(null);
  };

  const handleCloseSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    function handleTokenRefreshed() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        withUser(user);
      }
    }
    tokenRefreshedEventEmitter.addListener(
      'tokenRefreshed',
      handleTokenRefreshed,
    );
    return () =>
      tokenRefreshedEventEmitter.removeListener(
        'tokenRefreshed',
        handleTokenRefreshed,
      );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        Components={{
          novoCliente: NovoClienteSnackbar,
          googleAgenda: GoogleAgenda,
        }}
        classes={{
          containerRoot: classes.zindex,
          containerAnchorOriginTopRight: classes.containerTopOffset,
        }}
        maxSnack={3}
      >
        <SnackBarContext.Provider value={setSnackbar}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AbilityContext.Provider value={ability}>
              <FeaturesContext.Provider value={features}>
                <AutocompleteProvider>
                  <SWRConfig value={{ revalidateOnFocus: false }}>
                    <RealTimeProvider userToken={user?.token}>
                      {({ dispatch }) => (
                        <AuthProvider
                          withUser={withUser}
                          onLogout={onLogout(dispatch)}
                        >
                          <Router>
                            <VerifyLogin />
                            <VerifyAuthorization />
                            <RedirectNotFound />
                            <Switch>
                              <Route
                                path={loginRoutes.login}
                                component={Login}
                              />
                              <Route
                                path={loginRoutes.recoverPassword}
                                component={RecoverPassword}
                              />
                              <Route path="/init" component={ChangePassword} />
                              <Route
                                path="/alterar-senha"
                                component={ChangePassword}
                              />
                              <PrivateRoute
                                path="/"
                                render={() => <Home user={user} />}
                              />
                            </Switch>
                          </Router>
                        </AuthProvider>
                      )}
                    </RealTimeProvider>
                  </SWRConfig>
                </AutocompleteProvider>
              </FeaturesContext.Provider>
            </AbilityContext.Provider>
          </MuiPickersUtilsProvider>
          <MessageSnackbar
            onClose={handleCloseSnackbar}
            open={snackbar.open}
            severity={snackbar.state.severity}
            message={snackbar.state.message}
            duration={5000}
          />
        </SnackBarContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default withStyles(styles)(App);
