import React from 'react';
import FormGroup from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core/styles';
import Field from './Field';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatePicker from '@elentari/components-eve/components/DatePicker';
import moment from 'moment';
import { endOfDay } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: `solid 1px ${grey['400']}`,
    borderRadius: 28,
    position: 'relative',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  label: {
    position: 'absolute',
    left: theme.spacing(5),
    top: -7,
    padding: `0px ${theme.spacing(1 / 2)}px`,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  inputBase: {
    paddingLeft: '5px',
  },
  formControlInputLabel: {
    height: '100%',
  },
}));

const DateRange = ({
  labelMinValue = 'Início:',
  labelMaxValue = 'Fim:',
  labelGroup,
  nameMinValue,
  nameMaxValue,
  minFieldProps = {},
  maxFieldProps = {},
  onChangePeriodo = (name, value) => {
    // Intentionally left empty
  },
  formatDayMonth = false,
}) => {
  const classes = useStyles();

  const customProps = formatDayMonth
    ? { format: 'DD/MM', placeholder: moment().format('DD/MM') }
    : {};

  return (
    <FormGroup className={classes.root}>
      <FormLabel className={classes.label}>{labelGroup}</FormLabel>
      <Grid container spacing={1} justify="space-around">
        <Grid item lg={6}>
          <FormControlLabel
            control={
              <Field
                {...minFieldProps}
                name={nameMinValue}
                onChangePeriodo={onChangePeriodo}
              >
                {(props) =>
                  CustomDatePicker(
                    {
                      ...props,
                      ...customProps,
                    },
                    classes,
                  )
                }
              </Field>
            }
            label={labelMinValue}
            labelPlacement="start"
            classes={{ root: classes.formControlInputLabel }}
          />
        </Grid>
        <Grid item lg={5}>
          <FormControlLabel
            control={
              <Field
                {...maxFieldProps}
                name={nameMaxValue}
                style={{ paddingLeft: '17px' }}
                onChangePeriodo={(name, value) =>
                  onChangePeriodo(name, endOfDay(value.toDate()))
                }
              >
                {(props) =>
                  CustomDatePicker({ ...props, ...customProps }, classes)
                }
              </Field>
            }
            label={labelMaxValue}
            labelPlacement="start"
            classes={{ root: classes.formControlInputLabel }}
          />
        </Grid>
      </Grid>
    </FormGroup>
  );
};

const CustomDatePicker = ({ input, meta, ...props }, classes) => {
  return (
    <DatePicker
      {...input}
      {...props}
      format={props?.format || 'DD/MM/yyyy'}
      placeholder={props?.placeholder || moment().format('DD/MM/yyyy')}
      classes={{ root: classes.inputBase }}
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
      value={input.value || null}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      onChange={(date) => {
        input.onChange(date);
        props.onChangePeriodo(input.name, date);
      }}
      onBlur={input.onBlur}
    />
  );
};

export default DateRange;
