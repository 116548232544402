import { Login } from 'elentari';
import * as R from 'ramda';
import React from 'react';
import flipLogo from '../../icons/flip-logo.svg';
import { askForPermissioToReceiveNotifications } from '../../push-notification';
import api from '../../services/api';

export const handleLogin =
  ({ login, entityManager, setToken = R.empty, setFcmToken = R.empty }) =>
  async ({ username, password }) => {
    if (!username || !password) return;
    const response = await login({
      username: username.trim().toLowerCase(),
      password,
    });

    if (response.ok) {
      if (!response.data.user) {
        return { ok: false };
      }
      if (!response.data.accessToken) {
        return { ok: false, message: 'Token não encontrado' };
      }
      setToken(response.data.accessToken);
      const tokenFcm = sessionStorage.getItem('tokenFcm');
      if (tokenFcm) {
        setFcmToken(tokenFcm);
      } else {
        askForPermissioToReceiveNotifications();
      }

      const userData = {
        ok: true,
        token: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        id: response.data.user.id,
        roleId: response.data.user.roleId,
        name: response.data.user.name,
        email: username,
        roles: response.data.user.roles,
        teamId: response.data.user.teamId,
        isLider: response.data.user.leader,
        thumbnail: response.data.user.thumbnail,
        operatingSegment: response.data.user.operatingSegment,
        tenantId: response.data.user.tenantId,
        tenantName: response.data.user.tenantName,
        roleName: response.data.user.roleName,
        teamName: response.data.user.teamName,
        tourDone: response.data.user.tourDone,
        creci: response.data.user.creci,
        openWhatsappLinksOnApi:
          response.data.user.preferencias?.openWhatsappLinksOnApi || false,
      };

      return userData;
    }
    return { ok: false };
  };

const LoginForm = ({ history }) => {
  return (
    <Login
      history={history}
      onSubmit={handleLogin(api)}
      requiredLabel="Obrigatório"
      usernameLabel="Usuário"
      submitLabel="Entrar"
      passwordLabel="Senha"
      recoverPasswordLabel="Recuperar Senha"
      logo={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={flipLogo} alt="Flip Logo" height="80vh" />
        </div>
      }
    />
  );
};

export default LoginForm;
